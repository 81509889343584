import { useMutation } from '@apollo/client'

import { useMove } from '../../moves'
import * as mutations from './companyRatings.mutation'
import { type CompanyRatingPayload } from './companyRatings.types'

/**
 * rate moving company
 */
export const useRateCompanyBranchAction = () => {
  const { data: move } = useMove()
  const [rateCompanyBranch, { data, loading, ...mutationProps }] = useMutation(mutations.rateCompanyBranch)

  return {
    rateCompanyBranch: async (payload: CompanyRatingPayload) => {
      const { data } = await rateCompanyBranch({
        variables: {
          moveId: move.id,
          payload,
        },
      })
      return data
    },
    data: data?.rateCompanyBranch,
    loading,
    ...mutationProps,
  }
}

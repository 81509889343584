const EnglishContent: React.FC = () => (
  <>
    <p>
      The terms below apply to the reservation and the completion of the moving services that you are requesting to book through MovingWaldo, and that will be conducted by an independent moving company of your choosing. While MovingWaldo is not a moving company, we will handle the transaction aspect and we want to make sure that you understand the terms of the agreement in which you are about to enter.
    </p>

    <p>Once the moving company that you have selected has reviewed and accepted your booking request, MovingWaldo will send you a finalized Moving Estimate that will contain the terms and conditions listed below. You will be required to e-sign the Moving Estimate and place a $100 deposit in order to confirm your booking. If you have any questions related to these terms of services, please contact MovingWaldo's customer service.
    </p>

    <h2>Final Price</h2>

    <p>
      The price indicated in the present Moving Estimate is subject to be modified on the day of the move. On moving day, the moving company's representative will conduct a thorough verification of the information provided by the client notably, but not limited to, the quantity and nature of the items and rooms to be moved, the conditions of said items and the state of the premises in general. Following this verification, the material and equipment required to perform the moving services are subject to be adjusted.
    </p>

    <p>
      Similarly, the estimate given for the time required for the move in the Moving Estimate is not final and may differ from the final time required to complete the move, particularly but not exclusively due to the configuration of the departure and arrival locations or any imponderable beyond the control of the moving company. If the moving company is unable to fit all of the client's items in the moving truck, additional time will be required for additional trips.
    </p>

    <p>
      The Final Price will be confirmed to the client once the move is completed.
    </p>

    <h2>Payment</h2>

    <p>
      A $100 deposit will be debited from your credit card at the acceptance of this moving estimate. The remaining balance will be debited once the move is completed at the destination.
    </p>

    <h2>Cancellation</h2>

    <p>
      The client may at any time cancel the move provided for in the Moving Estimate within 10 business days of acceptance of the Moving Estimate but no later than 72 hours before the scheduled move date. Such cancellation shall be made in writing to MovingWaldo. MovingWaldo will confirm in writing to the client's email the cancellation of the move.
    </p>

    <p>
      After 10 business days following the acceptance of the Moving Estimate but no later than 72 hours before the scheduled move date, the client may cancel the move but a cancellation fee of $100 will be applicable and deducted from the client's credit card.
    </p>

    <p>
      If the cancellation requested by the client takes place less than 72 hours before the scheduled move, the client will have to pay a minimum of 3 X hourly rate stated in the above moving estimate as a cancellation fee.
    </p>

    <h2>Insurance</h2>

    <p>
      The moving company which will perform the move will be insured with a duly registered insurance company for public liability and property damage.
    </p>

    <h2>Liability of the moving company</h2>

    <p>
      The moving company exclusively shall be liable for any loss or damages done to the items of the client, except as hereinafter provided.
    </p>

    <p>
      The moving company shall not be liable for any pre-existing damages on the items of the client or any situation that might facilitate damages to the items that have not been disclosed prior to the start of the move.
    </p>

    <p>
      The moving company shall not be liable for any loss thereof or damage thereto or delay caused by an act of God,  the authority of law, or an act or default of the client.
    </p>

    <h2>Extraordinary value item</h2>

    <p>
      The moving company shall not carry or be liable in any way for documents, species, or for any items of extraordinary value not specifically declared in the Moving Estimate and a stipulated value of the items is provided. For more clarity, any item with a value of more than $5000 must be specifically declared by the client.
    </p>

    <h2>Dangerous items</h2>

    <p>
      The moving company may, at its own discretion, refuse to transport any items that may be considered dangerous under reasonable circumstances notably, but without limitation, any item that is potentially flammable, explosive or contains substances that are toxic or dangerous to the environment or people. The presence of any potentially dangerous item must be explicitly declared by the client in the Moving Estimate.
    </p>

    <h2>Failure to receive the items</h2>

    <p>
      Should the client refuse to receive all or part of the items to be moved, the moving company may, after sending a notice to the client, proceed with the sale of the goods and conserve the entire profit from the sale. Any costs incurred for the preservation of the client's items will be charged to the client.
    </p>

    <h2>Amendment</h2>

    <p>
      Any modification, addition, or erasure in this Moving Estimate which shall be made without the written approval of both parties shall be without effect.
    </p>
  </>
)

export default EnglishContent

import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

const useValidationSchema = () => {
  const { t } = useTranslation()
  return Yup.object().shape({
    overallRating: Yup.string().required(t('formValidation.required')),
  })
}

export default useValidationSchema

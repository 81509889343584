import { useTranslation } from 'react-i18next'
import CheckIcon from '@mui/icons-material/CheckCircleOutline'

import { useMove } from '../../../modules/moves'
import { PageContent } from '../../../components/Container'
import ContainedPage from '../../../components/layout/ContainedPage'
import { Heading } from '../../../components/typography'
import NumberedList, { NumberedListItem } from '../../../components/typography/NumberedList'
import FaqList from '../../../components/typography/Faq/FaqList'
import { FaqItem } from '../../../components/typography/Faq'

const QuoteAcceptedPage: React.FC = () => {
  const { t } = useTranslation()
  const { data: move } = useMove()
  const depositPaid = !!move.deposit

  const quote = move.quotes?.find(quote => quote.companyBranch.id === move.selectedBranch)
  const customizedQuote = !!quote?.customized

  return (
    <ContainedPage>
      <PageContent>
        <div className="flex justify-between gap-x-8">

          <div className="p-4 lg:p-12">
            <Heading variant="h1">
              <CheckIcon className="-mt-3 !text-[32px] text-green-800" />
              { ' ' }
              { t('pages.move.quoteAccepted.title') }
            </Heading>

            <Heading as="h2" variant="h3" className="my-8">
              { t('pages.move.quoteAccepted.whatNext.title') }:
            </Heading>

            { customizedQuote && depositPaid && (
              <div className="pt-2 text-xl text-gray-500">
                { t('pages.move.quoteAccepted.whatNext.expectAnEmailCustomizedQuote') }
              </div>
            ) }

            { !customizedQuote && depositPaid && (
              <div className="pt-2 text-xl text-gray-500">
                { t('pages.move.quoteAccepted.whatNext.expectAnEmail') }
              </div>
            ) }

            {
              !depositPaid &&
                (
                  <NumberedList>
                    <NumberedListItem number={1}>
                      { t('pages.move.quoteAccepted.whatNext.expectAnEmail') }
                    </NumberedListItem>

                    <NumberedListItem number={2}>
                      { t('pages.move.quoteAccepted.whatNext.contractAndDeposit') }
                    </NumberedListItem>

                    <NumberedListItem number={3}>
                      { t('pages.move.quoteAccepted.whatNext.allDone') }
                    </NumberedListItem>
                  </NumberedList>
                )
            }

            <Heading as="h2" variant="h3" className="mb-8 mt-16">
              { t('pages.move.quoteAccepted.faq.title') }
            </Heading>

            <FaqList>
              <FaqItem
                question={t('pages.move.quoteAccepted.faq.areMoversInsured.question')}
              >
                { t('pages.move.quoteAccepted.faq.areMoversInsured.answer') }
              </FaqItem>

              <FaqItem
                question={t('pages.move.quoteAccepted.faq.isQuoteAccurate.question')}
              >
                { t('pages.move.quoteAccepted.faq.isQuoteAccurate.answer') }
              </FaqItem>

              <FaqItem
                question={t('pages.move.quoteAccepted.faq.futureChanges.question')}
              >
                { t('pages.move.quoteAccepted.faq.futureChanges.answer') }
              </FaqItem>

            </FaqList>
          </div>

          <div className="sticky top-[100px] hidden h-full lg:block">
            <div className="relative w-[300px] overflow-hidden rounded-xl">
              <img
                src="/images/moving-photos/hero1.png"
                alt=""
                className="aspect-[2/5] w-full bg-gray-900/5 object-cover shadow-lg"
              />
              <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
          </div>

        </div>

      </PageContent>
    </ContainedPage>
  )
}

export default QuoteAcceptedPage

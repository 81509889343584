import { Truck } from '../quotes.types'

export const trucksVolume: Partial<Record<Truck, number>> = {
  [Truck.Trucks16ft]: 600,
  [Truck.Trucks20ft]: 900,
  [Truck.Trucks22ft]: 1150,
  [Truck.Trucks24ft]: 1400,
  [Truck.Trucks26ft]: 1650,
  [Truck.Trucks28ft]: 1800,
}

import { type CompanyRatingPayload } from '../../../modules/companyRatings'
import { type RatingFormValues } from './RatingForm.type'

export const formatFormOutput = (values: RatingFormValues): CompanyRatingPayload => {
  return {
    overallRating: parseInt(values.overallRating),
    professionalismRating: values.professionalismRating ? parseInt(values.professionalismRating) : undefined,
    punctualityRating: values.punctualityRating ? parseInt(values.punctualityRating) : undefined,
    communicationRating: values.communicationRating ? parseInt(values.communicationRating) : undefined,
    review: values.review ? values.review : undefined,
  }
}

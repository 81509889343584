import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isPast } from 'date-fns/isPast'
import CheckIcon from '@mui/icons-material/CheckCircleOutline'

import { useMove } from '../../../modules/moves'
import { type CompanyRatingPayload, useRateCompanyBranchAction } from '../../../modules/companyRatings'
import { useTranslatedValue } from '../../../common/translation'
import { capitalizeFirstLetter } from '../../../utils/string'
import { Heading, Subtitle } from '../../../components/typography'
import FullWidthPage from '../../../components/layout/FullWidthPage'
import { PageContent } from '../../../components/Container'
import RatingForm from '../../../components/forms/RatingForm'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification } from '../../../components/ToastNotifications'
import { Error404Page } from '../../errors'

const RatingPAge: React.FC = () => {
  const { t } = useTranslation()
  const { data: move } = useMove()
  const alreadyRated = !!move.companyRating
  const canRate = !!move.quoteAccepted?.date && isPast(move.movingDate) && !alreadyRated

  const getTranslation = useTranslatedValue()
  const { rateCompanyBranch } = useRateCompanyBranchAction()
  const [completed, setCompleted] = useState(false)
  const formatError = useErrorFormatter()

  const quote = move.quotes?.find(quote => quote.companyBranch.id === move.selectedBranch)

  const companyName = quote
    ? capitalizeFirstLetter(
      getTranslation(quote.companyBranch.company?.identification.name),
    )
    : undefined

  const onSubmit = async (payload: CompanyRatingPayload) => {
    try {
      await rateCompanyBranch(payload)
      setCompleted(true)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  if (completed || alreadyRated) {
    return (
      <FullWidthPage>
        <PageContent>
          <div className="my-8 p-4 text-center">
            <Heading variant="h1" className="flex items-center justify-center gap-x-2 text-green-700">
              <CheckIcon className="!text-[30px]" />
              { ' ' }
              { t('pages.rating.confirmation.title') }
            </Heading>
            <Subtitle className="py-4 text-lg text-black lg:text-center">
              { t('pages.rating.confirmation.thank') }
            </Subtitle>
          </div>
        </PageContent>
      </FullWidthPage>
    )
  }

  if (!canRate) {
    return <Error404Page />
  }

  return (
    <FullWidthPage>
      <PageContent>

        <div className="pt-4 text-center">
          <Heading variant="h1">
            <div className="mb-2 text-xl leading-none text-neutral-500 lg:mb-0 lg:text-4xl lg:leading-10">
              { t('pages.rating.title') }
            </div>
            { companyName }
          </Heading>

          <div className="my-12">
            <RatingForm onSubmit={onSubmit} />
          </div>
        </div>

      </PageContent>
    </FullWidthPage>
  )
}

export default RatingPAge
